import React from 'react';
import loadable from '@loadable/component';
import {
  CalendarOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  ShopOutlined,
  AlignCenterOutlined,
  SwapRightOutlined
} from '@ant-design/icons';
import { PUBLIC_ROUTE } from './routes.constants';
import { Redirect } from 'react-router';

export interface ISidebar {
  label: string;
  icon?: React.ReactNode;
}

export interface IRoute {
  exact?: boolean;
  visible?: boolean;
  path: string;
  component?: any;
  children?: Array<IRoute>;
  sidebar?: ISidebar;
}

export const publicRoutes: Array<IRoute> = [
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import('../pages/auth/Login')),
  },
];

interface IPrivateRoute extends IRoute {}

export const privateRoutes: Array<IPrivateRoute> = [
  {
    exact: true,
    path: '/dashboard',
    component: loadable(() => import('../pages/Dashboard')),
    sidebar: {
      icon: <ExclamationCircleOutlined />,
      label: 'Dashboard',
    },
  },
  {
    exact: true,
    path: '/user',
    sidebar: {
      icon: <UserOutlined />,
      label: 'User',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: 'list',
        },
        component: loadable(() => import('../pages/user')),
      },
      {
        exact: true,
        visible: false,
        path: '/register',
        component: loadable(() => import('../pages/user/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify',
        component: loadable(() => import('../pages/user/modify')),
      },
    ],
  },
  {
    exact: true,
    path: '/product',
    sidebar: {
      icon: <ShopOutlined />,
      label: 'Product',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: 'list',
        },
        component: loadable(() => import('../pages/product')),
      },
      {
        exact: true,
        visible: false,
        path: '/register',
        component: loadable(() => import('../pages/product/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify',
        component: loadable(() => import('../pages/product/modify')),
      },
    ],
  },
  {
    exact: true,
    path: '/detail',
    component: loadable(() => import('../pages/Detail')),
    sidebar: {
      icon: <AlignCenterOutlined />,
      label: 'Detail',
    },
  },
  {
    exact: true,
    path: '/step',
    component: loadable(() => import('../pages/Step')),
    sidebar: {
      icon: <SwapRightOutlined />,
      label: 'Step',
    },
  },
  {
    exact: true,
    path: '/calendar',
    component: loadable(() => import('../pages/Calendar')),
    sidebar: {
      icon: <CalendarOutlined />,
      label: 'Calendar',
    },
  },
];
